<template>
  <ValidationObserver ref="observer2" v-slot="{  }">
    <b-row>
      <b-col md="6">
        <ValidationProvider rules="required" name="Nombre">
          <b-form-group slot-scope="{ valid,errors }" prepend="Nombre">
            <label for="Nombre">Nombre</label>
            <b-form-input
              :disabled="isDisablebToEdit"
              type="text"
              :style="errors[0] || !valid ? 'border:1px red solid' : ''"
              placeholder="Ingrese Nombre"
              v-model="itinerario.nameBenefi"
              @input="setDataProduct()"
            >
            </b-form-input>
            <b-form-invalid-feedback>
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
      </b-col>
      <b-col md="6">
        <ValidationProvider rules="required" name="Apellido">
          <b-form-group slot-scope="{ valid,errors }" prepend="Apellido">
            <label for="apellido">Apellido</label>
            <b-form-input
              :disabled="isDisablebToEdit"
              type="text"
              :style="errors[0] || !valid ? 'border:1px red solid' : ''"
              placeholder="Ingrese Apellido"
              v-model="itinerario.lastNameBenefi"
              @input="setDataProduct()"
            >
            </b-form-input>
            <b-form-invalid-feedback>
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
      </b-col>
      <b-col md="6">
        <ValidationProvider rules="required" name="Tipo de servicio">
          <b-form-group slot-scope="{ valid, errors }" label="Tipo de servicio">
            <b-form-select
              :disabled="isDisablebToEdit"
              :state="errors[0] ? false : valid ? true : null"
              v-model="itinerario.trip"
              @input="manageServiceType"
            >
            <option :value="null" disabled>Seleccione un servicio</option>
            <option v-for="service in typeService" :key="service.id" :value="service.name">{{service.name}}</option>
            </b-form-select>
            <b-form-invalid-feedback>
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
      </b-col>
      <b-col md="6" v-if="showInputPax">
        <ValidationProvider rules="required" name="No. Pax">
          <b-form-group slot-scope="{ valid, errors }" label="No. Pax">
            <!-- :disabled="isDisablebToEdit" -->
            <b-form-input
              :disabled="isAnEdition "
              type="number"
              placeholder="Ingrese numero de pax"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              maxlength="3"
              v-model="itinerario.pax"
              :state="errors[0] ? false : valid ? true : null"
              @input="handlerPaxTransfer(product)"
              @keyup="setDataProduct()"
            >
            </b-form-input>
            <b-form-invalid-feedback>
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <b-alert show variant="danger" v-if="product.itinerario.maxPax > 0 ">
          <div class="alert-body text-center">
            <div>Maximo de pax permitidos: {{ product.itinerario.maxPax}}</div>
            <div>Pax extra: {{ product.itinerario.extraPax}}</div>
          </div>
        </b-alert>
      </b-col>
      <b-col md="6" v-if="product.transfer">
        <ValidationProvider  rules="required" name="Origen">
          <b-form-group slot-scope="{ errors }" prepend="Origen">
            <label for="Origen">Origen</label>
            <b-form-input
              :disabled="true"
              type="text"
              placeholder="Ingrese Origen"
              v-model="itinerario.originPlaceDisplay "
             
            >
            </b-form-input>
            <b-form-invalid-feedback>
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
      </b-col>


      <b-col md="6"  v-if="product.transfer ">
        <ValidationProvider rules="required" name="Destino">
          <b-form-group slot-scope="{ errors }" prepend="Destino">
            <label for="Origen">Destino</label>
            <b-form-input
              :disabled="true"
              type="text"
              placeholder="Ingrese Destino"
              v-model="itinerario.destinationPlaceDisplay "
             
            >
            </b-form-input>
            <b-form-invalid-feedback>
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
      </b-col>
      <b-col md="6"  v-if="!product.transfer">
        <ValidationProvider rules="required" name="Origen">
          <b-form-group slot-scope="{ valid, errors }" label="Origen">
            <b-form-select
              :disabled="isDisablebToEdit"
              placeholder="Enter the origin"
              :state="errors[0] ? false : valid ? true : null"
              v-model="itinerario.originPlaceDisplay"
              @change="setDataProduct()"
            >
            <option disabled value="">Seleccione un Origen</option>
            <option v-for="allowedorigins in setOriginAndDestiny.allowedorigins" :key="allowedorigins.id" :value="allowedorigins.name">{{allowedorigins.name}}</option>
            </b-form-select>
            <b-form-invalid-feedback>
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
      </b-col>
      <b-col md="6"  v-if="!product.transfer">
        <ValidationProvider rules="required" name="Destino">
          <b-form-group slot-scope="{ valid, errors }" label="Destino">
            <b-form-select
              :disabled="isDisablebToEdit"
              :state="errors[0] ? false : valid ? true : null"
              v-model="itinerario.destinationPlaceDisplay"
              @change="setDataProduct()"
            >
            <option disabled value="">Seleccione un Destino</option>
            <option v-for="alloweddestinies in setOriginAndDestiny.alloweddestinies" :key="alloweddestinies.id" :value="alloweddestinies.name">{{alloweddestinies.name}}</option>
            </b-form-select>
            <b-form-invalid-feedback>
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
      </b-col>
      <b-col md="6" >
        <ValidationProvider rules="required" name="Aerolínea">
          <b-form-group slot-scope="{ valid, errors }" label="Aerolínea">
            <v-select
              v-model="itinerario.airline"
              :disabled="isDisablebToEdit || disableAirline"
              label="name"
              :options="airlines"
              :style="errors[0] || !valid || itinerario.airline == null ? 'border:1px red solid; border-radius: 5px' : ''"
              class="mb-1"
              @input="setDataProduct()"
            />
            <b-form-invalid-feedback>{{ errors[0] }} </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
      </b-col>
      <b-col md="3" v-if="!noAirline">
        <ValidationProvider rules="required" name="No. de Vuelo">
          <b-form-group slot-scope="{ valid, errors }" label="No. de Vuelo">
            <b-form-input
              :disabled="isDisablebToEdit"
              type="text"
              placeholder="Ingrese vuelo"
              v-model="itinerario.flight"
              :state="errors[0] ? false : valid ? true : null"
              @input="setDataProduct()"
            >
            </b-form-input>
            <b-form-invalid-feedback>
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>

      </b-col>
      <b-col md="3" v-if="!noAirline">
        <ValidationProvider rules="required" name="Tipo de Vuelo">
          <b-form-group slot-scope="{ valid, errors }" label="Tipo de Vuelo">
            <b-form-select
              :disabled="isDisablebToEdit"
              :state="errors[0] ? false : valid ? true : null"
              v-model="itinerario.typeflight"
              :options="typeFlightOptions"
              @input="setDataProduct()"
            >
            </b-form-select>
            <b-form-invalid-feedback>
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
      </b-col>
      <b-col md="6" v-if="!noAirline">
        <TimePicker
            :label="'Hr. Vuelo'"
            :hour="itinerario.flighttime"
            :disabled="isDisablebToEdit || noAirline"
            :required="false"
            @set-time="setTimeFlight" 
            @set-errors="setErrorTime"
        />
      </b-col>
      <b-col md="6">
        <TimePicker
            v-if="!isSetingTimes"
            :label="'Hr. Pick Up'" :hour="itinerario.pickup" :disabled="isDisablebToEdit || handlePickup" 
            @set-time="setTimePickup"
            @set-errors="setErrorTime"
            :required="false"
        />        
      </b-col>
      <b-col md="" class="pt-1" v-if="noAirline">
        <b-alert show variant="danger">
          <div class="alert-body text-center">
            <span>Este servicio no requiere hora de vuelo</span>
          </div>
        </b-alert>
      </b-col>
    </b-row>
  </ValidationObserver>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

import TimePicker from '@/shared/TimePicker'
import vSelect from 'vue-select'
import * as moment from 'moment'
import { utils } from "@/modules/fivesClub/mixins/utils"
import { market } from '@/modules/shop/mixins/market'

export default {
  mixins: [utils, market],
  mounted(){

    this.setDataProduct()
    if(this.product.transfer) return false 
    if (!this.itinerario.typeflight || this.itinerario.typeflight == '') this.itinerario.typeflight = 'N/A'
    if (this.itinerario.trip == '') this.itinerario.trip = null
    if( this.isAnEdition ){
        const errors = !this.itinerario.pickup || !this.itinerario.flighttime ? 'erorrs' : null
        console.log( errors)
        this.setErrorTime( errors )
    }
  },
  components:{
    vSelect,
    TimePicker
  },
  props:{
    product:{
      type: Object,
      required: true
    },
    itinerario:{
      type: Object,
      required: true
    },
    isDisablebToEdit: {
      type: Boolean,
      default: false
    },
    isAnEdition: {
      type: Boolean,
      required: true
    },
    showInputPax: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      typeFlightOptions: [
        'N/A',
        'Nacional',
        'Internacional'
      ],
      noAirline: false,
      isSetingTimes: false
    }
  },
  computed: {
    ...mapState("start", ["destinations", "airlines", "typeService", "itineraryRules"]),
    setOriginAndDestiny() {
      const { allowedorigins, alloweddestinies } = this.typeService.find(service => service.name === this.itinerario.trip) ?? {};

      return {allowedorigins: allowedorigins?.length > 0 ? allowedorigins : [], alloweddestinies: alloweddestinies?.length > 0 ? alloweddestinies : []}
    },
    disableAirline() {
      return !this.typeService?.find(service => service.name == this.itinerario.trip)?.allowAirline // si no permite la aerolinea, desabilita la seleccion
    },
    handlePickup(){
      if (this.itinerario?.trip == "Arrival" || this.itinerario?.trip == "Departure") return !this.noAirline // Una excepción para editar pickup en caso de 'No aplica' como aerolinea
    },
  },
  methods: {
    ...mapActions('mySales',['getServiceTypesAndItineraryRules']),
    ...mapMutations("shop", ["setDataProductItem"]),
    async handlerPaxTransfer(product){
      if (!this.isAnEdition) this.$emit('handler-pax-transfer', product)
    },
    manageServiceType(){
      this.setDataProduct()
      if(this.product.transfer) return false 
      this.itinerario.originPlaceDisplay = ''
      this.itinerario.destinationPlaceDisplay = ''

    },
    setDataProduct() {
                  
      let currentService = this.typeService.find(service => service.name == this.itinerario?.trip)
      if (!currentService){
        currentService = [];
        currentService.push({ sameoriginanddestiny: null });

      }

      // Si solo existe un origen o destino disponible, lo asigno automáticamente
      if ( currentService?.allowedorigins?.length == 1) this.itinerario.originPlaceDisplay = currentService?.allowedorigins[0].name
      else if ( !currentService?.allowedorigins && !this.product.transfer ) this.itinerario.originPlaceDisplay = ''
      if ( currentService?.alloweddestinies?.length == 1) this.itinerario.destinationPlaceDisplay = currentService?.alloweddestinies[0].name
      else if ( !currentService?.alloweddestinies && !this.product.transfer ) this.itinerario.destinationPlaceDisplay = ''

      if (!currentService.sameoriginanddestiny && !this.product.transfer){
        if (this.itinerario?.originPlaceDisplay == this.itinerario?.destinationPlaceDisplay) { // Si la salida es igual a la llegada, borro
          this.itinerario.destinationPlaceDisplay = ""
          this.itinerario.originPlaceDisplay = ""
        }
        if (this.itinerario.destinationPlaceDisplay == this.itinerario?.originPlaceDisplay) { // Si la llegada es igual a la salida, borro
          this.itinerario.originPlaceDisplay = ""
          this.itinerario.destinationPlaceDisplay = ""
        }
      }

      if ( !currentService.allowAirline ) { // Si el servicio no admite aerolinea o la aerolinea es 'No Aplica', el numero de vuelo se anula
        this.itinerario.airline = { id: 117, name: "No Aplica" }
        this.itinerario.flight = 'N/A'
        this.itinerario.typeflight = 'N/A'
        this.itinerario.flighttime = null
      } else {
        if ( this.itinerario?.trip == "Arrival" ) this.itinerario.pickup = this.itinerario?.flighttime

        // Cálculo de las horas de vuelo y pickup
        if ( this.itinerario?.trip == "Departure" && this.itinerario?.flighttime && this.itinerario?.typeflight) {
          let hora
          let hourstring

          // Transformo las horas a un valor válido
          hourstring = this.itinerario?.flighttime
          let extraPickupTime=0
          let currentDestination= currentService.alloweddestinies.find(destination => destination.name == this.itinerario?.destinationPlaceDisplay)
          if (currentDestination?.name=="Aeropuerto Tulum") extraPickupTime=1

          if (this.itinerario.typeflight == "Nacional") hora = moment(hourstring, 'HH:mm').subtract(this.itineraryRules?.nationalDelay+extraPickupTime, 'hours') // si es nacional, el pickup se hace horas antes según parámetros
          if (this.itinerario.typeflight == "Internacional") hora = moment(hourstring, 'HH:mm').subtract(this.itineraryRules?.internationalDelay+extraPickupTime, 'hours') // si es internacional, el pickup se hace horas antes según parámetros

          this.itinerario.pickup =  hora?.format('HH:mm') || this.itinerario?.flighttime
        }
      }
  
      this.setDataProductItem(this.product)
    },
    setTimeFlight(time){	
        this.isSetingTimes = true
        this.itinerario.flighttime = time
        this.setDataProduct()
        setTimeout(() => {
            this.isSetingTimes = false            
        }, 50)
    },
    setTimePickup(time){
        this.itinerario.pickup = time
        this.setDataProduct()
    },
    setErrorTime( error ){
		const isValidTime = error ? true : false
        this.$emit('set-is-valid-time', isValidTime )
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
