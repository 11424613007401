var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer2",scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Nombre"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"prepend":"Nombre"}},[_c('label',{attrs:{"for":"Nombre"}},[_vm._v("Nombre")]),_c('b-form-input',{style:(errors[0] || !valid ? 'border:1px red solid' : ''),attrs:{"disabled":_vm.isDisablebToEdit,"type":"text","placeholder":"Ingrese Nombre"},on:{"input":function($event){return _vm.setDataProduct()}},model:{value:(_vm.itinerario.nameBenefi),callback:function ($$v) {_vm.$set(_vm.itinerario, "nameBenefi", $$v)},expression:"itinerario.nameBenefi"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Apellido"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"prepend":"Apellido"}},[_c('label',{attrs:{"for":"apellido"}},[_vm._v("Apellido")]),_c('b-form-input',{style:(errors[0] || !valid ? 'border:1px red solid' : ''),attrs:{"disabled":_vm.isDisablebToEdit,"type":"text","placeholder":"Ingrese Apellido"},on:{"input":function($event){return _vm.setDataProduct()}},model:{value:(_vm.itinerario.lastNameBenefi),callback:function ($$v) {_vm.$set(_vm.itinerario, "lastNameBenefi", $$v)},expression:"itinerario.lastNameBenefi"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Tipo de servicio"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Tipo de servicio"}},[_c('b-form-select',{attrs:{"disabled":_vm.isDisablebToEdit,"state":errors[0] ? false : valid ? true : null},on:{"input":_vm.manageServiceType},model:{value:(_vm.itinerario.trip),callback:function ($$v) {_vm.$set(_vm.itinerario, "trip", $$v)},expression:"itinerario.trip"}},[_c('option',{attrs:{"disabled":""},domProps:{"value":null}},[_vm._v("Seleccione un servicio")]),_vm._l((_vm.typeService),function(service){return _c('option',{key:service.id,domProps:{"value":service.name}},[_vm._v(_vm._s(service.name))])})],2),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),(_vm.showInputPax)?_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"No. Pax"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"No. Pax"}},[_c('b-form-input',{attrs:{"disabled":_vm.isAnEdition,"type":"number","placeholder":"Ingrese numero de pax","oninput":"javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);","maxlength":"3","state":errors[0] ? false : valid ? true : null},on:{"input":function($event){return _vm.handlerPaxTransfer(_vm.product)},"keyup":function($event){return _vm.setDataProduct()}},model:{value:(_vm.itinerario.pax),callback:function ($$v) {_vm.$set(_vm.itinerario, "pax", $$v)},expression:"itinerario.pax"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)}),(_vm.product.itinerario.maxPax > 0 )?_c('b-alert',{attrs:{"show":"","variant":"danger"}},[_c('div',{staticClass:"alert-body text-center"},[_c('div',[_vm._v("Maximo de pax permitidos: "+_vm._s(_vm.product.itinerario.maxPax))]),_c('div',[_vm._v("Pax extra: "+_vm._s(_vm.product.itinerario.extraPax))])])]):_vm._e()],1):_vm._e(),(_vm.product.transfer)?_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Origen"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('b-form-group',{attrs:{"prepend":"Origen"}},[_c('label',{attrs:{"for":"Origen"}},[_vm._v("Origen")]),_c('b-form-input',{attrs:{"disabled":true,"type":"text","placeholder":"Ingrese Origen"},model:{value:(_vm.itinerario.originPlaceDisplay ),callback:function ($$v) {_vm.$set(_vm.itinerario, "originPlaceDisplay", $$v)},expression:"itinerario.originPlaceDisplay "}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1):_vm._e(),(_vm.product.transfer )?_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Destino"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('b-form-group',{attrs:{"prepend":"Destino"}},[_c('label',{attrs:{"for":"Origen"}},[_vm._v("Destino")]),_c('b-form-input',{attrs:{"disabled":true,"type":"text","placeholder":"Ingrese Destino"},model:{value:(_vm.itinerario.destinationPlaceDisplay ),callback:function ($$v) {_vm.$set(_vm.itinerario, "destinationPlaceDisplay", $$v)},expression:"itinerario.destinationPlaceDisplay "}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1):_vm._e(),(!_vm.product.transfer)?_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Origen"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Origen"}},[_c('b-form-select',{attrs:{"disabled":_vm.isDisablebToEdit,"placeholder":"Enter the origin","state":errors[0] ? false : valid ? true : null},on:{"change":function($event){return _vm.setDataProduct()}},model:{value:(_vm.itinerario.originPlaceDisplay),callback:function ($$v) {_vm.$set(_vm.itinerario, "originPlaceDisplay", $$v)},expression:"itinerario.originPlaceDisplay"}},[_c('option',{attrs:{"disabled":"","value":""}},[_vm._v("Seleccione un Origen")]),_vm._l((_vm.setOriginAndDestiny.allowedorigins),function(allowedorigins){return _c('option',{key:allowedorigins.id,domProps:{"value":allowedorigins.name}},[_vm._v(_vm._s(allowedorigins.name))])})],2),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1):_vm._e(),(!_vm.product.transfer)?_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Destino"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Destino"}},[_c('b-form-select',{attrs:{"disabled":_vm.isDisablebToEdit,"state":errors[0] ? false : valid ? true : null},on:{"change":function($event){return _vm.setDataProduct()}},model:{value:(_vm.itinerario.destinationPlaceDisplay),callback:function ($$v) {_vm.$set(_vm.itinerario, "destinationPlaceDisplay", $$v)},expression:"itinerario.destinationPlaceDisplay"}},[_c('option',{attrs:{"disabled":"","value":""}},[_vm._v("Seleccione un Destino")]),_vm._l((_vm.setOriginAndDestiny.alloweddestinies),function(alloweddestinies){return _c('option',{key:alloweddestinies.id,domProps:{"value":alloweddestinies.name}},[_vm._v(_vm._s(alloweddestinies.name))])})],2),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1):_vm._e(),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Aerolínea"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Aerolínea"}},[_c('v-select',{staticClass:"mb-1",style:(errors[0] || !valid || _vm.itinerario.airline == null ? 'border:1px red solid; border-radius: 5px' : ''),attrs:{"disabled":_vm.isDisablebToEdit || _vm.disableAirline,"label":"name","options":_vm.airlines},on:{"input":function($event){return _vm.setDataProduct()}},model:{value:(_vm.itinerario.airline),callback:function ($$v) {_vm.$set(_vm.itinerario, "airline", $$v)},expression:"itinerario.airline"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),(!_vm.noAirline)?_c('b-col',{attrs:{"md":"3"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"No. de Vuelo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"No. de Vuelo"}},[_c('b-form-input',{attrs:{"disabled":_vm.isDisablebToEdit,"type":"text","placeholder":"Ingrese vuelo","state":errors[0] ? false : valid ? true : null},on:{"input":function($event){return _vm.setDataProduct()}},model:{value:(_vm.itinerario.flight),callback:function ($$v) {_vm.$set(_vm.itinerario, "flight", $$v)},expression:"itinerario.flight"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1):_vm._e(),(!_vm.noAirline)?_c('b-col',{attrs:{"md":"3"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Tipo de Vuelo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Tipo de Vuelo"}},[_c('b-form-select',{attrs:{"disabled":_vm.isDisablebToEdit,"state":errors[0] ? false : valid ? true : null,"options":_vm.typeFlightOptions},on:{"input":function($event){return _vm.setDataProduct()}},model:{value:(_vm.itinerario.typeflight),callback:function ($$v) {_vm.$set(_vm.itinerario, "typeflight", $$v)},expression:"itinerario.typeflight"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1):_vm._e(),(!_vm.noAirline)?_c('b-col',{attrs:{"md":"6"}},[_c('TimePicker',{attrs:{"label":'Hr. Vuelo',"hour":_vm.itinerario.flighttime,"disabled":_vm.isDisablebToEdit || _vm.noAirline,"required":false},on:{"set-time":_vm.setTimeFlight,"set-errors":_vm.setErrorTime}})],1):_vm._e(),_c('b-col',{attrs:{"md":"6"}},[(!_vm.isSetingTimes)?_c('TimePicker',{attrs:{"label":'Hr. Pick Up',"hour":_vm.itinerario.pickup,"disabled":_vm.isDisablebToEdit || _vm.handlePickup,"required":false},on:{"set-time":_vm.setTimePickup,"set-errors":_vm.setErrorTime}}):_vm._e()],1),(_vm.noAirline)?_c('b-col',{staticClass:"pt-1",attrs:{"md":""}},[_c('b-alert',{attrs:{"show":"","variant":"danger"}},[_c('div',{staticClass:"alert-body text-center"},[_c('span',[_vm._v("Este servicio no requiere hora de vuelo")])])])],1):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }