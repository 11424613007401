<template>
    <div>
        <span>{{ label }}</span>   
        <div class="d-flex align-items-center">
            <select v-model="selectedHour" :disabled="disabled" class="form-control custom-scroll" @change="updateFinalTime">
                <option :value="null">HH</option>
                <option v-for="hour in hours" :key="hour" :value="hour">{{ hour }}</option>
            </select>
          
            <b class="span-two-points">:</b>
          
            <select v-model="selectedMinute" :disabled="disabled" class="form-control custom-scroll" @change="updateFinalTime">
                <option :value="null">MM</option>
                <option v-for="minute in minutes" :key="minute" :value="minute">{{ minute }}</option>
            </select>          
        </div>        
        <div class="text-danger" v-if="errors">{{ errors }}</div>
    </div>      
</template>  
  
<script>
  
export default {
    name: 'TimeSelector',
    props: {
        hour: {
            type: String,
            default: null
        },
        steps: {
            type: Number,
            default: 1
        },
        label: {
            type: String,
            default: 'Hora'
        },
      
        disabled: {
            type: Boolean,
            default: false
        },
        format: {
            type: String,
            default: '24'
        },
        required: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            selectedHour: null,
            selectedMinute: null,
            hours: [],
            minutes: [],
            finalTime: null,
            errors: null
        };
    },
    mounted() {
        this.generateHours()
        this.generateMinutes()

        if ( this.hour ) {
            const [ hour, minute ] = this.hour.split(':')
            this.selectedHour = hour;
            this.selectedMinute = minute
            this.updateFinalTime()
        }
        this.validateFinalTime()
    },
    methods: {
        generateHours() {
            this.hours = []
            for (let hour = 0; hour <= 23; hour++) {
                this.hours.push(hour.toString().padStart(2, '0'))
            }
        },
        generateMinutes() {
            this.minutes = [];
            for (let minute = 0; minute <= 59; minute += this.steps) {
                this.minutes.push(minute.toString().padStart(2, '0'))
            }
        },
        updateFinalTime() {
            
            if ( this.selectedHour !== null && this.selectedMinute === null ) {
                this.finalTime = `${this.selectedHour}:`;
            }

            if ( this.selectedHour === null && this.selectedMinute !== null ) {
                this.finalTime = `:${this.selectedMinute}`;
            }
            
            if ( this.selectedHour === null && this.selectedMinute === null ) {
                this.finalTime = null
            }

            if ( this.selectedHour !== null && this.selectedMinute !== null ) {
                this.finalTime = `${this.selectedHour}:${ this.selectedMinute}`
                this.$emit('set-time', this.finalTime)
                this.$emit('set-errors', null)
            }

            this.validateFinalTime()
        },
        validateFinalTime(){
            this.errors = null
            this.$emit('set-errors', this.errors)
            if( this.required ){
                if( this.finalTime === null ){
                    this.errors = 'Hora y minuto requeridos'
                    this.$emit('set-errors', this.errors)
                    return
                }
    
                const [ hour, minute ] = this.finalTime.split(':')
    
                if ( !hour ) {
                    this.errors = 'La hora es requerida'
                    this.$emit('set-errors', this.errors)
                    return
                }
    
                if (!minute  ) {
                    this.errors = 'El minuto es requerido'
                    this.$emit('set-errors', this.errors)
                    return
                }
            }
        }
        
    }
}

</script>
  
<style scoped>
.d-flex.align-items-center > input,
.d-flex.align-items-center > select {
    flex: 1;
}

.b-period {
    margin-left: 6px;
}
.span-two-points{
    margin-left: 3px;
    margin-right: 3px;
}
/* Estilos personalizados para el scroll */
.custom-scroll::-webkit-scrollbar {
    width: 6px;
}

.custom-scroll::-webkit-scrollbar-thumb {
    background-color: #0006;
    border-radius: 4px;
}

.custom-scroll::-webkit-scrollbar-thumb:hover {
    background-color: #0006;
}

.custom-scroll::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}
</style>
  